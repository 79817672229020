import {
  Button,
  OdinIcon,
  OdinIconSize,
  OdinIconType,
} from '@myosh/odin-components';
import { useTranslation } from 'react-i18next';

import useAppStateStore from 'Store/AppState.store';

const FiltersAction = () => {
  const { t } = useTranslation();
  const { handleClearFilters, handleApplyFilters } =
    useAppStateStore.getState();
  const isFiltersDirty = useAppStateStore((s) => s.isFiltersDirty);

  const handleOnApply = () => {
    if (!isFiltersDirty) {
      return;
    }

    handleApplyFilters();
  };

  return (
    <div className="Filters__action odin-flex odin-justify-center odin-px-2 odin-py-3 odin-gap-2">
      <Button
        onClick={handleClearFilters}
        title={t('reset_filters')}
        aria-label={t('reset_filters')}
        type="default"
        classNames="Filters__action"
      >
        <OdinIcon
          icon="FilterOff"
          type={OdinIconType.Line}
          size={OdinIconSize.ExtraSmall}
        />
        {t('reset')}
      </Button>
      <Button
        onClick={handleOnApply}
        title={t('apply_filters')}
        aria-label={t('apply_filters')}
        classNames={`Filters__action ${
          !isFiltersDirty ? 'Filters__action--disabled' : ''
        }`}
        type="primary"
        aria-disabled={!isFiltersDirty}
      >
        <OdinIcon
          icon="FindReplace"
          type={OdinIconType.Line}
          size={OdinIconSize.ExtraSmall}
        />
        {t('apply_filters')}
      </Button>
    </div>
  );
};

export default FiltersAction;
