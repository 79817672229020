import { useEffect, useMemo, useRef } from 'react';

import { filterDate, transformScheduleData } from 'Helpers/scheduler';
import useFetchData from 'Hooks/useFetchData';
import useAppStateStore from 'Store/AppState.store';
import { ENDPOINTS, PAGE_SIZE } from 'Helpers/constants';
import { Store } from '@bryntum/calendar';

interface FetchSchedulerDataProps {
  formIds: string;
  hierarchyIds: string;
  dateStart?: string;
  dateEnd?: string;
  hierarchyTypes?: string[];
  page: number;
}

// utility method for constructing the data fetch url
const buildUrl = ({
  formIds,
  hierarchyIds,
  dateStart,
  dateEnd,
  page,
}: Omit<FetchSchedulerDataProps, 'hierarchyTypes'>) => {
  const urlParts = [];
  if (formIds !== '' && dateStart && dateEnd) {
    urlParts.push(`filter=moduleFormId:in:${formIds}`);
    urlParts.push(`startDate=${filterDate(dateStart as string)}`);
    urlParts.push(`endDate=${filterDate(dateEnd as string)}`);
    urlParts.push(`pageSize=${PAGE_SIZE}`);
    urlParts.push(`page=${page}`);

    if (hierarchyIds !== '') {
      urlParts.push(`filter=hierarchyFieldId:in:${hierarchyIds}`);
    }
  }

  return urlParts.length > 0
    ? `${ENDPOINTS.SCHEDULES}?${urlParts.join('&')}`
    : undefined;
};

// Hook that provides the bryntum Store object used for the calendar
const useSchedulerData = ({
  formIds,
  hierarchyIds,
  dateStart,
  dateEnd,
  hierarchyTypes,
  page,
}: FetchSchedulerDataProps) => {
  const storeRef = useRef<Store>();

  const { handleSetFilteringLoader } = useAppStateStore.getState();
  const isFiltering = useAppStateStore((state) => state.isFiltering);

  const url = buildUrl({ formIds, hierarchyIds, page, dateStart, dateEnd });
  const { data, isValidating } = useFetchData(url);

  const storeData = useMemo(() => {
    return !isValidating ? transformScheduleData(data?.items) : undefined;
  }, [data, isValidating]);

  // 2. Transform API Data into a consumable Store data
  useEffect(() => {
    if (isFiltering && storeData && hierarchyTypes) {
      storeRef.current = new Store({
        fields: ['description', 'date', ...hierarchyTypes],
        data: storeData,
        autoCommit: true,
        syncDataOnLoad: true,
      });

      // 3. Flip `isFiltering` State
      handleSetFilteringLoader(false);
    }
  }, [storeData, isFiltering, hierarchyTypes, handleSetFilteringLoader]);

  return {
    store: storeRef.current,
    data,
    isLoading: isFiltering || isValidating,
  };
};

export default useSchedulerData;
