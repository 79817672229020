import { useMemo } from 'react';
import { t } from 'i18next';

import { CalendarType } from 'Store/AppState.store';
import { WEEK_VIEW, MONTH_VIEW, YEAR_VIEW } from 'Helpers/constants';
import usePaginationConfig from './usePaginationConfig';
import useColumnsConfig from './useColumnsConfig';

const listFeatures = {
  filterBar: {
    hidden: true,
  },
  regionResize: true,
  columnAutoWidth: false,
  group: false,
  columnReorder: false,
  headerMenu: {
    items: {
      pinColumn: {
        text: t('pin_column'),
        icon: 'b-fa-location-pin-lock',
        weight: '220',
        onItem: (item: Record<string, any>) => {
          // eslint-disable-next-line no-param-reassign
          item.column.locked = !item.column.locked;
        },
      },
      hideColumn: {
        text: t('hide_column'),
      },
      columnPicker: {
        text: t('columns'),
      },
      sortAsc: {
        text: t('sort_asc'),
      },
      sortDesc: {
        text: t('sort_desc'),
      },
      multiSort: false,
      toggleFilterBar: {
        text: t('show_filter_bar'),
      },
    },
    processItems({ items, column }: any) {
      if (column.field === 'date') {
        // eslint-disable-next-line no-param-reassign
        items.pinColumn = false;
        // eslint-disable-next-line no-param-reassign
        items.hideColumn = false;
        // eslint-disable-next-line no-param-reassign
        items.sortAsc = false;
        // eslint-disable-next-line no-param-reassign
        items.sortDesc = false;
      }
      if (column.data.locked || column.data.region === 'locked') {
        // eslint-disable-next-line no-param-reassign
        items.pinColumn.text = t('unpin_column');
      }
      if (!items.toggleFilterBar.localeClass.hidden) {
        // eslint-disable-next-line no-param-reassign
        items.toggleFilterBar.text = t('hide_filter_bar');
      }
    },
  },
  stripe: false,
  cellTooltip: {
    showOn: 'hover',
    align: 'l-r',
    hoverDelay: 200,
    hideDelay: 200,
    autoClose: false,
    closable: true,
    forSelector: '.Schedules__record-count',
  },
};

interface ModesProps {
  page: number;
  availablePages?: number;
  totalSize?: number;
  leadColumns: Record<string, unknown>[];
  hierarchyTypes?: string[];
  calendar: CalendarType;
}

// Provides the BryntumCalendar modes configuration
const useModesConfig = ({
  page,
  availablePages,
  totalSize,
  leadColumns,
  hierarchyTypes,
  calendar,
}: ModesProps) => {
  const { paginationConfig } = usePaginationConfig({
    page,
    availablePages,
    totalSize,
  });

  const { handleGenerateColumns } = useColumnsConfig(
    leadColumns,
    calendar,
    hierarchyTypes
  );

  const modes = useMemo(() => {
    return hierarchyTypes
      ? {
          // Built-in modes; calendar views
          agenda: null,
          day: null,

          // Custom modes
          week: {
            type: 'eventlist',
            title: t(WEEK_VIEW),
            features: listFeatures,
            columns: handleGenerateColumns(WEEK_VIEW),
            bbar: paginationConfig(),
          },
          month: {
            type: 'eventlist',
            title: t(MONTH_VIEW),
            features: listFeatures,
            columns: handleGenerateColumns(MONTH_VIEW),
            bbar: paginationConfig(),
          },
          year: {
            type: 'eventlist',
            title: t(YEAR_VIEW),
            features: listFeatures,
            columns: handleGenerateColumns(YEAR_VIEW),
            bbar: paginationConfig(),
          },
        }
      : undefined;
  }, [handleGenerateColumns, paginationConfig, hierarchyTypes]);

  return { modes };
};

export default useModesConfig;
