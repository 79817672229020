import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAppStateStore from 'Store/AppState.store';
import ErrorBoundary from 'Components/shared/ErrorBoundary';
import StatsCard from 'Modules/StatsCard/StatsCard';

import { COMPLETED_STATUSES, ENDPOINTS } from 'Helpers/constants';
import { filterDate } from 'Helpers/scheduler';
import { isObjectEmpty } from 'Helpers/helpers';
import useFetchData from 'Hooks/useFetchData';

import './Stats.scss';

type RecordCountDetail = Record<string, number>;

const recordCountInitial = {
  overdue: 0,
  progress: 0,
  completed: 0,
  completed_percent: 0,
  planned: 0,
};

const Stats = () => {
  const { t } = useTranslation();

  // due to how zustand is wired, this is needed to ensure stats are re-rendered when filtering is in progress
  useAppStateStore((state) => state.isFiltering);

  const filterFormIdsRef = useRef(useAppStateStore.getState().filterFormIds);
  const hierarchyIdsRef = useRef(useAppStateStore.getState().filters.hierarchy);
  const calendarStatesRef = useRef(useAppStateStore.getState().calendar);
  const isFilteringRef = useRef(useAppStateStore.getState().isFiltering);
  const recordTypeRef = useRef(useAppStateStore.getState().filters.recordType);

  const { dateStart, dateEnd } = calendarStatesRef.current;

  const [recordCount, setRecordCount] =
    useState<RecordCountDetail>(recordCountInitial);

  const shouldShowPlannedCount = Number(recordTypeRef.current) !== 2; // only fetched when 'scheduled' records filter is active

  // Records count data
  const urlParts = [];
  if (filterFormIdsRef.current !== '') {
    urlParts.push(`filter=formId:in:${filterFormIdsRef.current}`);
    urlParts.push(
      `filter=creationDate:gte:${filterDate(dateStart as string, true)}`
    );
    urlParts.push(
      `filter=creationDate:lte:${filterDate(dateEnd as string, true)}`
    );

    if (hierarchyIdsRef.current.length > 0) {
      hierarchyIdsRef.current.forEach((hierarchy: any) => {
        urlParts.push(
          `filter=HIERARCHY:${hierarchy[0]}:id_in:${hierarchy[1].join(',')}`
        );
      });
    }

    if (shouldShowPlannedCount) {
      urlParts.push(`filter=excludeNonRecurs:eq:true`); // tells the API we are fetching count for 'scheduled' records
    }
  }

  const url =
    urlParts.length > 0
      ? `${ENDPOINTS.RECORD_COUNT}?${urlParts.join('&')}`
      : undefined;

  const { isLoading, data } = useFetchData(url);

  // Planned count
  const plannedCountFilters = [];
  if (shouldShowPlannedCount && filterFormIdsRef.current !== '') {
    plannedCountFilters.push(
      `filter=moduleFormId:in:${filterFormIdsRef.current}`
    );
    plannedCountFilters.push(`startDate=${filterDate(dateStart as string)}`);
    plannedCountFilters.push(`endDate=${filterDate(dateEnd as string)}`);
    if (hierarchyIdsRef.current.length > 0) {
      const ids: number[] = [];
      hierarchyIdsRef.current.forEach((hierarchy: any) =>
        ids.push(...hierarchy[1])
      );
      plannedCountFilters.push(`filter=hierarchyFieldId:in:${ids.join(',')}`);
    }
  }

  const plannedCountUrl =
    shouldShowPlannedCount && plannedCountFilters.length > 0
      ? `${
          ENDPOINTS.SCHEDULES_COUNT_PROJECTED_RECORDS
        }?${plannedCountFilters.join('&')}`
      : undefined;

  const { isLoading: plannedCountLoading, data: projectedCountData } =
    useFetchData(plannedCountUrl);

  if (
    shouldShowPlannedCount &&
    !plannedCountLoading &&
    projectedCountData &&
    projectedCountData.projectedCount !== recordCount.planned
  ) {
    setRecordCount({
      ...recordCount,
      planned: projectedCountData.projectedCount,
    });
  }

  // Connect to the store on mount, disconnect on unmount,
  // catch state-changes in a reference
  useEffect(() => {
    useAppStateStore.subscribe(
      // eslint-disable-next-line no-return-assign
      (state) => (
        // eslint-disable-next-line no-sequences
        (isFilteringRef.current = state.isFiltering),
        (calendarStatesRef.current = state.calendar),
        (filterFormIdsRef.current = state.filterFormIds),
        (hierarchyIdsRef.current = state.filters.hierarchy),
        (recordTypeRef.current = state.filters.recordType)
      )
    );
  }, []);

  // Transform Data into a consumable Record Count data
  useEffect(() => {
    const handleGetCounts = (result: object) => {
      const resultAr = result ? (Object.values(result).flat() as []) : [];
      let totalRecordCount = 0;

      const count = resultAr.reduce(
        (acc: RecordCountDetail, obj: RecordCountDetail) => {
          Object.keys(obj).forEach((key) => {
            const isCompletedStatus = COMPLETED_STATUSES.includes(key);
            let keyString = key.toLowerCase();

            if (isCompletedStatus) {
              keyString = 'completed';
            } else if (!isCompletedStatus && keyString !== 'overdue') {
              keyString = 'progress';
            }
            acc[keyString] = acc[keyString] || 0;
            acc[keyString] += obj[key];
            totalRecordCount += obj[key];
          });

          return acc;
        },
        {}
      );

      return { count, totalRecordCount };
    };

    if (data) {
      const { count, totalRecordCount } = handleGetCounts(data.result);

      if (!isObjectEmpty(count)) {
        setRecordCount({
          ...recordCountInitial,
          ...count,
          completed_percent:
            Math.floor((count.completed / totalRecordCount) * 100) || 0,
        });
      }
    } else {
      setRecordCount(recordCountInitial);
    }
  }, [data]);

  return (
    <div className="Stats">
      <ErrorBoundary>
        <StatsCard
          type="overdue"
          label={t('overdue')}
          isLoading={isLoading}
          value={recordCount.overdue.toString()}
        />
        <StatsCard
          type="progress"
          label={t('in_progress')}
          isLoading={isLoading}
          value={recordCount.progress.toString()}
        />
        <StatsCard
          type="completed"
          label={t('completed')}
          isLoading={isLoading}
          value={recordCount.completed.toString()}
        />
        <StatsCard
          type="completed"
          label={t('completed_percent')}
          isLoading={isLoading}
          value={`${recordCount.completed_percent.toString()}`}
        />
        {shouldShowPlannedCount && (
          <StatsCard
            type="future"
            label={t('planned')}
            isLoading={plannedCountLoading}
            value={recordCount.planned.toString()}
          />
        )}
      </ErrorBoundary>
    </div>
  );
};

export default Stats;
